// @flow

import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Icon,
	PopupSelect,
	List,
	themes,
	PopupWhite,
	Box,
	Text,
	LoaderIcon,
} from '@graphite/uneon';
import type { TPublicationStatus } from '@graphite/types';
import { ThemeProvider } from 'emotion-theming';
import { useSelector, useDispatch } from '@graphite/use-redux';
import { getPublishStatus } from 'Editor/selectors/editor';
import { publish } from '../../ducks/publish';

type TProps = $ReadOnly<{|
	goToDashboard: () => void,
|}>;

const Burger = styled(Button)`
	margin-right: 24px;
	padding: 0;
	display: flex;
	align-items: center;
`;

const titleSx = { marginBottom: '18px' };

const procContenerSx = { display: 'flex', marginBottom: '18px', flexDirection: 'row' };

const textSx = { marginLeft: '12px' };

function Builder({ goToDashboard }: TProps) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const publishStatus: TPublicationStatus = useSelector(getPublishStatus);
	const [isOpenMenu, setStateMenu] = React.useState(false);
	const [isRealBurger, setRealBurger] = React.useState(false);
	const burgerRef = React.useRef();

	const toggleOpenMenu = React.useCallback(() => {
		setStateMenu(!isOpenMenu);
	}, [isOpenMenu]);

	const toggleBurger = React.useCallback(
		e => {
			e.preventDefault();
			setRealBurger(!isRealBurger);
		},
		[isRealBurger],
	);

	const boundClick = React.useCallback(
		(e, itemName) => {
			if (itemName === 'dashboard') goToDashboard();
			if (itemName === 'publication') {
				setStateMenu(false);
				dispatch(publish('export'));
			}
		},
		[dispatch, goToDashboard],
	);

	const list = React.useMemo(
		() => ({
			items: [
				{
					name: 'dashboard',
					label: t('Back to Dashboard'),
				},
				{
					name: 'publication',
					label: t('Export as HTML/CSS'),
				},
			],
			colors: 'primaryflat',
			activeColors: 'primaryflat',
			behavior: 'button',
		}),
		[t],
	);

	return (
		<>
			<Burger
				ref={burgerRef}
				title={t('Dashboard')}
				variant="secondaryflat.icon.sm"
				onClick={toggleOpenMenu}
				onContextMenu={toggleBurger}
			>
				{isRealBurger ? (
					<Icon name="burger-color" iconSize={24} size="sm" />
				) : (
					<Icon colors="secondaryflat" name="text-align-justify" size="sm" />
				)}
			</Burger>

			<ThemeProvider theme={themes.light}>
				<PopupWhite
					isOpen={publishStatus === 'exporting'}
					anchorEl={burgerRef}
					offsetTop={0}
					offsetLeft={0}
					mutex="topleft"
					isFixed
					noDrag
				>
					<Box>
						<Text variant="title4" color="text.primaryalt" sx={titleSx}>
							{t('HTML/CSS')}
						</Text>
						<Box sx={procContenerSx}>
							<LoaderIcon color="bg.accent" />

							<Text variant="bodysm" color="text.primary" sx={textSx}>
								{t('Exporting')}
							</Text>
						</Box>
					</Box>
				</PopupWhite>
			</ThemeProvider>

			{isOpenMenu && (
				<PopupSelect
					isOpen={isOpenMenu}
					anchorEl={burgerRef}
					offsetTop={0}
					offsetLeft={0}
					onClose={toggleOpenMenu}
					width="auto"
				>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<List {...list} onClick={boundClick} />
				</PopupSelect>
			)}
		</>
	);
}

export default React.memo<TProps>(Builder);
