// @flow
import React from 'react';
import type { TWidgetOwnProps } from '@graphite/types';
import { getTrueIds, getWidget } from '@graphite/selectors';
import { useDispatch, useSelector } from '@graphite/use-redux';
import logger from 'libs/logger';

import { placeWidget } from 'Editor/ducks/widgets';
import { logOut } from 'Editor/ducks/users';

import User from './User';

const UserEdit = ({ id }: TWidgetOwnProps) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		logger.info('dashboardVisit');
	}, []);

	const user = useSelector(state => getWidget(state, { id }));

	const onInsert = React.useCallback(() => {
		dispatch(
			placeWidget('project', id, null, id, {
				kind: 'grid',
				prevId: null,
				nextId: null,
				destRect: null,
				dragRect: null,
				breakpoints: null,
			}),
		);
	}, [dispatch, id]);

	// eslint-disable-next-line no-void
	const logOutHandler = React.useCallback(() => void dispatch(logOut()), [dispatch]);

	const babies = user
		? getTrueIds({
				...user,
				currentDevice: 'desktop', // Мы в дашбоарде, здесь нет девайсов
		  })
		: [];

	return <User id={id} babies={babies} onInsert={onInsert} logOut={logOutHandler} />;
};

export default React.memo<TWidgetOwnProps>(UserEdit);
