// @flow
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'emotion-theming';
import { Button, themes, PopupWhite, Box, Text, Link, LoaderIcon } from '@graphite/uneon';
import { getCurrentUser, getCurrentSiteId } from '@graphite/selectors';
import type { TId, TPublicationStatus } from '@graphite/types';
import { useInjectSaga } from 'libs/inject-saga';
import { useInjectReducer } from 'libs/inject-reducer';
import { useDispatch, useSelector } from '@graphite/use-redux';
import { getPublishStatus } from 'Editor/selectors/editor';
import publishReducer, {
	fetchIfNeeded,
	publish,
	saga as sagaPublish,
} from '../ducks/publish';

import { PANEL_DY, PANEL_DX } from './constants';

const HEIGHT = 42; // (54 - 30) / 2 + 30

type TProps = $ReadOnly<{|
	publishUrl: string,
|}>;

const Wrapper = styled.div`
	position: relative;
	margin-left: 12px;
`;

const titleSx = {
	marginBottom: '18px',
};

const linkSx = {
	textDecoration: 'none',
};

const textSx = {
	marginBottom: '12px',
};

const LoaderBox = styled.div`
	background-color: ${({ theme }) => theme.colors.bg.accent};
	position: absolute;
	border-radius: ${({ theme }) => theme.radii.rounded.all};
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

function TopBar({ publishUrl }: TProps) {
	useInjectSaga({ key: 'publish', saga: sagaPublish });
	useInjectReducer({ key: 'publish', reducer: publishReducer });

	const dispatch = useDispatch();
	const user = useSelector(getCurrentUser);
	const siteId: ?TId = useSelector(getCurrentSiteId);
	React.useEffect(() => {
		dispatch(fetchIfNeeded(user?._id, siteId));

		return () => {
			dispatch(fetchIfNeeded(null));
		};
	}, [dispatch, siteId, user]);

	const [isOpenPublishLink, setStatePopupLink] = React.useState(false);
	const panelRef = React.useRef();

	const publishStatus: TPublicationStatus = useSelector(getPublishStatus);

	const { t } = useTranslation();

	const publishing = React.useCallback(() => {
		dispatch(publish());
		setStatePopupLink(true);
	}, [dispatch]);

	const closePublishPopup = React.useCallback(() => {
		setStatePopupLink(false);
	}, []);

	return (
		<>
			<Wrapper ref={panelRef}>
				<Button variant="accent.rounded.sm" onClick={publishing}>
					{t('Publish')}
				</Button>
				{publishStatus === 'publishing' && (
					<LoaderBox>
						<LoaderIcon />
					</LoaderBox>
				)}
			</Wrapper>
			<ThemeProvider theme={themes.light}>
				<PopupWhite
					isOpen={
						isOpenPublishLink &&
						['published', 'error'].includes(publishStatus)
					}
					anchorEl={panelRef}
					offsetTop={HEIGHT + PANEL_DY}
					offsetLeft={PANEL_DX}
					onClose={closePublishPopup}
					mutex="topright"
					isFixed
					noDrag
				>
					{isOpenPublishLink && (
						<Box>
							<Text variant="title4" color="text.primaryalt" sx={titleSx}>
								{t('Published')}
							</Text>
							{publishUrl && (
								<Link
									href={publishUrl}
									rel="noopener noreferrer"
									target="_blank"
									sx={linkSx}
								>
									<Text
										variant="bodysm"
										color="text.accent"
										sx={textSx}
									>
										{publishUrl.replace('http://', '')}
									</Text>
								</Link>
							)}
							{publishStatus === 'error' && t('Publishing error occured!')}
						</Box>
					)}
				</PopupWhite>
			</ThemeProvider>
		</>
	);
}

export default React.memo<TProps>(TopBar);
